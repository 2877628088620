/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import loadable from '@loadable/component';
import {
    shape, array, string,
} from 'prop-types';

const GqlGiftFinder = loadable(() => import(/* webpackChunkName: "GiftFinder" */'./GraphqlFoodGiftFinder'));
const GqlGiftFinderV2 = loadable(() => import(/* webpackChunkName: "GiftFinder" */'./GraphqlFoodGiftFinder_v2'));

function GraphqlFoodGiftFinderContainer(props) {
    const { data } = props;
    // const GiftFinder = <GqlGiftFinder />;
    // const GiftFinderV2 = <GqlGiftFinderV2 />;
    if (!data.reference.length) return <></>;

    const version = data.reference[0]['_content_type_uid'];
    const loadVersion = version === 'gift_finder_gfb_v2' ? <GqlGiftFinderV2 data={data} /> : <GqlGiftFinder data={data} />;
    return loadVersion;
}

GraphqlFoodGiftFinderContainer.propTypes = {
    data: shape({
        finder_criteria: shape({
            occasion: shape({
                value: array.isRequired,
            }),
            price: shape({
                value: array.isRequired,
            }),
            recipient: shape({
                value: array.isRequired,
            }),
        }),
        heading: string.isRequired,
        collection_path: string.isRequired,
    }).isRequired,
};

export default GraphqlFoodGiftFinderContainer;
